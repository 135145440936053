import React, { FC } from 'react'
import { graphql } from 'gatsby'
import Layout from 'layout'
import {
  Container,
  Grid,
  makeStyles,
  Theme,
  Link,
  Breadcrumbs,
  Typography,
} from '@material-ui/core'
import MainPosts from 'components/blog/mainPosts'
import Sidebar from 'components/blog/sidebar'
import BlogHeader from 'components/blog/header'
import HomeIcon from '@material-ui/icons/Home'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  content: {
    padding: theme.spacing(2, 0, 2),
  },
  Blog: {
    marginTop: theme.spacing(4),
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}))

interface BlogArchiveProps {
  data: {
    allMdx: {
      edges: {
        node: {
          id: string
          excerpt: string
          frontmatter: {
            date: string
            description: string
            title: string
          }
          slug: string
        }
      }[]
    }
  }
  pageContext: {
    monthAndYear: string
  }
}

const Template: FC<BlogArchiveProps> = ({
  data: {
    allMdx: { edges: archivePosts },
  }, // this prop will be injected by the GraphQL query below.
  pageContext,
}) => {
  const classes = useStyles()

  const posts = archivePosts.map(post => ({
    title: post.node.frontmatter.title,
    date: post.node.frontmatter.date,
    slug: post.node.slug,
    excerpt: post.node.excerpt,
  }))

  const description = `Read our blogs from ${pageContext.monthAndYear}, we bring you the latest eBay changes and explain how they might affect you or your business`
  const title = `Read eBay related blogs for ${pageContext.monthAndYear}`

  return (
    <Layout>
      <GatsbySeo
        title={title}
        description={description}
        openGraph={{ title: title, description: description }}
      />
      <div className={classes.content}>
        <Container maxWidth="lg">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<DoubleArrowIcon fontSize="small" />}
          >
            <Link href="/" className={classes.link}>
              <HomeIcon className={classes.icon} />
              Home
            </Link>{' '}
            <Link href="/blog" className={classes.link}>
              <ViewQuiltIcon className={classes.icon} />
              Blog
            </Link>
            <Typography color="textPrimary">
              {pageContext.monthAndYear}
            </Typography>
          </Breadcrumbs>
          <div className={classes.Blog}>
            <Grid container spacing={4} justify="center">
              <BlogHeader />
            </Grid>
            <Grid container spacing={5} className={classes.mainGrid}>
              <MainPosts
                title={`${pageContext.monthAndYear} Blogs`}
                posts={posts}
              />
              <Sidebar />
            </Grid>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Template

export const pageQuery = graphql`
  query($monthAndYear: String!) {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { monthAndYear: { eq: $monthAndYear } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            description
            title
          }
          slug
        }
      }
    }
  }
`
